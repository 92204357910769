import React from "react";

export default class Footer extends React.Component {
    render() {
        return (
            <div>
                <footer id="footer">
                    <div className="container">
                        <div className="social-links">
                            <a href="https://stackoverflow.com/users/241022/simon" className="stack-overflow"><i
                                className="bx bxl-stack-overflow"/></a>
                            <a href="https://github.com/obiwan87" className="github"><i className="bx bxl-github"/></a>
                            <a href="https://t.me/obijuan87" className="telegram"><i className="bx bxl-telegram"/></a>
                            <a href="https://www.linkedin.com/in/antonino-simone-di-stefano-549305151?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bj0viAGLXRW%2BMIGunPsfcWA%3D%3D"
                               className="linkedin"><i className="bx bxl-linkedin"/></a>
                            <a href="mailto:simon.distefano@gmx.de"><i className="bx bx-mail-send"/></a>
                        </div>
                        <div className="row justify-content-center">
                            <p>Antonino Simone Di Stefano</p>
                            <p>Wolfratshauser Str. 80, 81379 München</p>
                            <p>Tel. +4917672677953</p>
                            <p><a href="mailto:simom.distefano@gmx.de">simon.distefano@gmx.de</a></p>
                        </div>
                        <div className="copyright">
                            &copy; Copyright <strong><span>Antonino Simone Di Stefano</span></strong>. All Rights
                            Reserved
                        </div>
                        <div className="credits">
                            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}