import React from "react";

export default class Skill extends React.Component {
    render() {
        return (
            <div className="progress">
                <span className="skill">{this.props.skill.name} <i className="val">{this.props.skill.level}%</i></span>
                <div className="progress-bar-wrap">
                    <div className="progress-bar" role="progressbar" aria-valuenow={this.props.skill.level}
                         aria-valuemin="0" aria-valuemax="100"/>
                </div>
            </div>
        )
    }
}