import React from "react";

export default class Downloads extends React.Component {
    render() {
        return (
            <>
                <div className="container" id="downloads">
                    <div className="row justify-content-center download-row">
                        <div className="col-lg-auto text-center download-item">
                            <a href="/assets/pdf/Simone%20Di%20Stefano%20-%20English%20-%20CV.pdf"
                               className="btn btn-outline-primary"><i
                                className="bx bi-download"/> Resume PDF
                            </a>
                        </div>
                        <div className="col-lg-auto text-center download-item">
                            <a href="/assets/pdf/Simone%20Di%20Stefano%20-%20English%20-%20Projektprofil.pdf"
                               className="btn btn-outline-primary"><i
                                className="bx bi-download"/> Project Portfolio PDF
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}