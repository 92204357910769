export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function compare(i1, i2) {
    if (i1 > i2)
        return 1;
    if(i2 > i1)
        return -1;
    return 0;
}