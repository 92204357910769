import React from "react";
import ResumeItem from "./ResumeItem";

export default class ResumeSection extends React.Component {
    render() {
        let resumeItems = this.props.experiences.map((experience, index) => <ResumeItem experience={experience} key={index}/>)
        return (
            <div className="row justify-content-md-center">
                <div className="col-lg-2 offset-lg-2">
                    <h3 className="resume-title">{this.props.groupName}</h3>
                </div>
                <div className="col-lg-8">
                    {resumeItems}
                </div>
            </div>
        );
    }
}