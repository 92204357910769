import React from "react";
import configData from '../config/application.json'
import {groupBy, compare} from "../commons/arrays";
import SkillsGroup from "./SkillsGroup";

export default class SkillsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {skills: []}
    }

    fetchSkills = () => {
        this.setState({...this.state, isFetching: true});
        fetch(`${configData.directus.baseUrl}/skills?fields=*.*`, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${configData.directus.bearerToken}`
            })
        })
            .then(response => response.json())
            .then(result => this.setState({skills: result.data, isFetching: false}))
            .catch(e => {
                console.log(e);
            });
    };

    render() {
        let skills = this.getSkillsGroupsComponents();
        return (
            <section id="skills" className="skills section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Skills</h2>
                    </div>

                    <div className="row skills-content justify-content-center">
                        <div className="col-lg-6">
                            {skills}
                        </div>
                    </div>

                </div>
            </section>
        )
    }

    getSkillsGroupsComponents() {


        function compareSkills(s1, s2) {

            let c1 = compare(s1.category.sort, s2.category.sort);
            if(c1 === 0) {
                return -compare(s1.level, s2.level);
            }
            return c1;
        }

        let skillsArray = this.state.skills instanceof Array ? this.state.skills : [];
        skillsArray.sort(compareSkills);

        return Array
            .from(groupBy(skillsArray, x => x.category.name).entries())
            .map(([groupName, skills]) => <SkillsGroup key={groupName}
                                                       groupName={groupName}
                                                       skills={skills}/>);
    }

    componentDidMount() {
        this.fetchSkills()
    }
}