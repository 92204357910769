import React from "react";
import SocialLinks from "./SocialLinks";

export default class HeroSection extends React.Component {
    render() {
        return (
            <section id="hero" className="d-flex flex-column justify-content-center">
                <div className="container" data-aos="zoom-in" data-aos-delay="100">
                    <div className="row hero-logo">
                        <a href='/index.html'>
                            <i className="bx icon-lasagnerd-logo"> &gt; _ lasagnerd</i>
                        </a>
                    </div>
                    <div className="row introduction">
                        <h3>Hey, I'm</h3>
                        <h1>Simone Di Stefano</h1>
                        <p>Freelance Data Engineer & Software Engineer</p>
                    </div>
                    <SocialLinks/>
                </div>
            </section>
        );
    }
}