import React from "react";
import Skill from "./Skill";

export default class SkillsGroup extends React.Component {
    render() {
        let skills = this.props.skills.map(
            x => <Skill key={x.name} skill={x}/>
        )
        return (
            <div className="row skills-content">
                <h3 className="skills skill-group-title">{this.props.groupName}</h3>
                {skills}
            </div>
        );
    }
}