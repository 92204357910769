import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Scripts from "./components/Scripts";
import SkillsSection from "./components/SkillsSection";
import Resume from "./components/Resume";
import React from "react";
import Footer from "./components/Footer";

export default class App extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <HeroSection/>
                <SkillsSection />
                <Resume />
                <Footer />
                <Scripts/>
            </>
        );
    }
}
