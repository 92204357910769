import React from "react";

export default class SocialLinks extends React.Component {
    render() {
        return (
            <>
                <div className="row social-links">
                    <div className="col-md-12">
                        <a href="https://stackoverflow.com/users/241022/simon" className="stackoverflow"><i className="bx bxl-stack-overflow"/></a>
                        <a href="https://github.com/obiwan87" className="github"><i className="bx bxl-github"/></a>
                        <a href="https://t.me/obijuan87" className="telegram"><i className="bx bxl-telegram"/></a>
                        <a href="https://www.linkedin.com/in/antonino-simone-di-stefano-549305151?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bj0viAGLXRW%2BMIGunPsfcWA%3D%3D"
                           className="linkedin"><i className="bx bxl-linkedin"/></a>
                        <a href="mailto:simon.distefano@gmx.de"><i className="bx bx-mail-send"/></a>
                    </div>
                </div>
                <div className="row social-links">
                    <div className="col-md-12">
                        <a href="https://stackoverflow.com/users/241022/simon"><img
                            src="https://stackoverflow.com/users/flair/241022.png?theme=clean" width="208" height="58"
                            alt="profile for Simon at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                            title="profile for Simon at Stack Overflow, Q&amp;A for professional and enthusiast programmers"/></a>
                    </div>
                </div>
            </>
        );
    }
}