import React from "react";
import Downloads from "./Downloads";
import configData from "../config/application.json";
import {compare, groupBy} from "../commons/arrays";
import ResumeSection from "./ResumeSection";

export default class Resume extends React.Component {
    constructor(props) {
        super(props);
        this.state = {experiences: []}
    }

    fetchExperiences = () => {
        this.setState({...this.state, isFetching: true});
        fetch(`${configData.directus.baseUrl}/experiences?fields=*.*`, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${configData.directus.bearerToken}`
            })
        })
            .then(response => response.json())
            .then(result => this.setState({experiences: result.data, isFetching: false}))
            .catch(e => {
                console.log(e);
            });
    };

    render() {
        let resumeSectionsComponents = this.getResumeSectionsComponents();
        return (
            <section id="resume" className="resume">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Resume</h2>
                    </div>
                    {resumeSectionsComponents}
                    <Downloads/>
                </div>
            </section>
        )
    }

    getResumeSectionsComponents() {
        function compareExperiences(e1, e2) {
            let c1 = compare(e1.resume_section.sort, e2.resume_section.sort);
            if (c1 === 0) {
                return -compare(e1.from, e2.from);
            }
            return c1;
        }

        let experiencesArray = this.state.experiences instanceof Array ? this.state.experiences : [];
        experiencesArray.sort(compareExperiences);

        return Array
            .from(groupBy(experiencesArray, experience => experience.resume_section.name).entries())
            .map(([groupName, experiences]) => <ResumeSection groupName={groupName}
                                                              experiences={experiences}
                                                              key={groupName}/>);
    }

    componentDidMount() {
        this.fetchExperiences();
    }
}