// noinspection HtmlUnknownAnchorTarget

import React from "react";

export default class Header extends React.Component {
    render() {
        return (
            <>
                <i className="bi bi-list mobile-nav-toggle d-xl-none"/>
                <header id="header" className="d-flex flex-column justify-content-center">

                    <nav id="navbar" className="navbar nav-menu">
                        <ul>
                            <li><a href="#hero" className="nav-link scrollto active"><i className="bx bx-home"/>
                                <span>Home</span></a></li>
                            <li><a href="#skills" className="nav-link scrollto"><i className="bx bi-list-check"/>
                                <span>Skills</span></a>
                            </li>
                            <li><a href="#resume" className="nav-link scrollto"><i className="bx bx-file-blank"/>
                                <span>Resume</span></a></li>
                            <li/>
                        </ul>
                    </nav>
                </header>
            </>
        );
    }
}