import React from "react";

export default class Scripts extends React.Component {


    render() {
        return (
            <>
            </>
        );
    }

    componentDidMount() {
        let appendScript = (scriptToAppend) => {
            const script = document.createElement("script");
            script.src = scriptToAppend;
            script.async = false;
            document.body.appendChild(script);
        }
        let scripts = ["/assets/vendor/purecounter/purecounter.js",
            "/assets/vendor/aos/aos.js",
            "/assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
            "/assets/vendor/glightbox/js/glightbox.min.js",
            "/assets/vendor/isotope-layout/isotope.pkgd.min.js",
            "/assets/vendor/swiper/swiper-bundle.min.js",
            "/assets/vendor/typed.js/typed.min.js",
            "/assets/vendor/waypoints/noframework.waypoints.js",
            "/assets/vendor/php-email-form/validate.js",
            "/assets/js/main.js"]

        for (let script of scripts) {
            appendScript(script)
        }
    }


}