import React from "react";
import dateFormat from "dateformat";

export default class ResumeItem extends React.Component {
    render() {
        const DATE_FORMAT = 'mmm yyyy';

        let experience = this.props.experience;
        let from = dateFormat(experience.from, DATE_FORMAT);
        let to = dateFormat(experience.to, DATE_FORMAT);
        return (

            <div className="resume-item">
                <h4>{experience.title}</h4>
                <h5>{from} - {to}</h5>
                <p><em>{experience.context}</em></p>
                <div dangerouslySetInnerHTML={{__html: experience.summary}}/>
            </div>
        );
    }
}